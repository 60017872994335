import React from "react"
import antivirusIcon from "../../assets/images/sistem-antivirus.png"
import notifIcon from "../../assets/images/sistem-notificari.png"
import servIcon from "../../assets/images/servicii-web.png"
import {Link} from "gatsby"

const SiteBenefits = () => {
    return (
        <section id="site-benefits">
            <div className="container">
                <div className="title-row">
                    <h2 className="h3-title text-center mb-4">Here is why you should
                        choose <strong>SiteBunker</strong> web
                        hosting services:</h2>
                </div>
                <div className="row">
                    <div className="col-md-4 benefit-col">
                        <img src={servIcon} loading="lazy" alt=""/>
                        <h3 className="benefits-title">PREMIUM WEB HOSTING<p>FOR ANY BUDGET</p></h3>
                        <p>We guarantee premium storage, RAM and processing resources for your web hosting requirements
                            through subscriptions which are very affordable and easy to use. Ask for details <Link
                                to="/contact/">here.</Link></p>
                    </div>
                    <div className="col-md-4 benefit-col">
                        <img src={notifIcon} loading="lazy" alt=""/>
                        <h3 className="benefits-title">AUTOMATIC DAILY BACKUP <p>IN EXTERNAL LOCATION</p></h3>
                        <p>All your data is safe. SiteBunker guarantees FREE automatic backup, in a secure external
                            location. This
                            way, you can be relaxed about your website and concentrate on your sales objectives. </p>
                    </div>
                    <div className="col-md-4 benefit-col">
                        <img src={antivirusIcon} loading="lazy" alt=""/>
                        <h3 className="benefits-title">PERFORMANT SECURITY <p>ANTI-DDoS PROTECTION</p></h3>
                        <p>Alongside SiteBunker you enjoy more than just a simple web hosting. We also take care of your
                            protection.
                            Our hosting services security is constantly improved, including for DDoS attacks.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SiteBenefits
