import React from "react"
import { Link } from "gatsby"
import techImg from "../../assets/images/new-imgs/technical-assistance.png"


const CustomerSupport = () => {
  return (
    <section id="customer-support">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={techImg} alt="Web Hosting" loading="lazy"/>
            </div>
          </div>
          <div className="col-md-6 info-text">
            <h2 className="mb-5 h3-title">24/7 Customer Support included</h2>
            <p>No matter the technical challenge you face or if you simply have a question about our web hosting
              services, the SiteBunker customer support team is at your disposal 24/7, all year round.</p>
            <p className="mb-5 "> Contact us by using our ticketing system and we’ll reply in a short time.</p>
            <Link to="/contact/" className="view-more-button">TEST OUR SUPPORT</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CustomerSupport;
