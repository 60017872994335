import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import IntroHostingPlans from "../components/HostingPlans/IntroHostingPlans"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import PromoInfoRow from "../components/PromoInfoRow/PromoInfoRow"
import InfoBlocks from "../components/Index/InfoBlocks"
import CustomerSupport from "../components/Index/CustomerSupport"
import SiteBenefits from "../components/SiteBenefits/SiteBenefits"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import FreeHosting from "../components/Common/FreeHosting";
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";
import hostingImg from "../assets/images/hosting-discount.png";


const WebSsdHosting = () => {
    return (
        <Layout>
            <SEO title="Complete Web Hosting Solutions - Sitebunker.net - Premium resources"
                 description="Are you looking for affordable web hosting services? Sitebunker.net provides complete hosting solutions designed for any budget."
            />
            <Navbar/>

            <IntroHostingBgWhite
                title={'Web hosting packages on high-performance servers for efficient hosting'}
                subtitle={'Choose the right package for your needs and enjoy the security of premium services.'}
            />


            <HostingPlanBlock category="Web SSD EN"
                              discount='40'
                              showPromos={false}/>

            <BannerArea
                pageTitle={["PERFORMANT SSD WEB HOSTING"]}
                pageSubtitle="Powered by  LiteSpeed Web Server"
                pageDescription="Choose the right web hosting subscription for you, regardless of the online platform you use. "
                pageDescriptionTwo="Browse available options below and contact us for a more customized solution. We will identify your technical requirements and will provide the best solution, personalized for your needs."
                svgIllustration="web-ssd"
            />


            <FreeHosting image={hostingImg}
                         title='Get a great discount for annual payment'
                         text1='Choose an annual hosting package and enjoy the 40% discount. We have customized solutions for any of your website’s needs.'
                         text2='The offer is available for all the above packages.'/>
            <PromoInfoRow/>
            <InfoBlocks/>
            <CustomerSupport/>
            <SiteBenefits/>
            <ClientFeedback
                title="Curious why our clients choose"
                bold="SiteBunker Web Hosting?"/>
            <Footer/>
        </Layout>
    )
}

export default WebSsdHosting
