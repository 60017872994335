import React from "react"
import ReactTooltip from "react-tooltip"
import aboutUs from "../../assets/images/new-imgs/about-us-2.png"


const PromoInfoRow = () => {
  return (
    <section>
      <section id="promo-info-row">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 info-text">
              <h2 className="h3-title mb-3">Supreme web hosting</h2>
              <p>Your online success always starts with an efficient web hosting. All our subscriptions are designed for
                it, so that you can be able to focus on what matters the most. </p>
              <p>Forget about hosting worries! SiteBunker is here.</p>

              <h2 className="h3-title mb-3 mt-5">Choose the Sitebunker <br></br> Support Team</h2>
              <p>Choose the annual payment method and additionally to all the premium processing resources, storage and RAM, you will benefit from 40% discount.</p>
            </div>
            <div className="col-md-6 position-relative">
              <div className="svg-img">
                <img src={aboutUs} loading="lazy" alt="Superlative website hosting" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="packages-intro">
        <div className="packages-area pb-2 hosting-plans-intro container-fluid pt-100">
          <div className="container">
            <div className="section-title section-title-800">
              <h3 className="h3-title align-items-center text-white">Free web hosting transfer from another provider
                <i className="ml-1 bx bxs-message-rounded-error heartbeat"
                   data-for="info-message"
                   data-place="top"
                   data-tip="Offer available only if cPanel access is provided. * Migrations possible only Monday to Friday, 09.00-23.00"
                   data-effect="solid"
                />
              </h3>
              <ReactTooltip id="info-message" />
              <p className="text-white mb-50">Past experiences with other web hosting providers didn’t satisfy your
                needs? Are you looking for a new partner to offer you efficient and customized hosting services?
                SiteBunker guarantees a free, simple and fast transfer.</p>
              <p className="text-white mb-50"> Our support team will take care of the entire process and even guide you
                into choosing the most suitable resources.</p>
            </div>
          </div>
        </div>
      </section>
    </section>

  )
}

export default PromoInfoRow
